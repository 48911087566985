import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [0,13,5,4,9,10,16,17,6,2];

export const dictionary = {
		"/(marketing)": [~20,[3]],
		"/(marketing)/app": [~24,[3]],
		"/blog": [~38,[13]],
		"/blog/[slug]": [~39,[13]],
		"/cart/products/[slug]": [~40,[14]],
		"/(marketing)/contact": [~25,[3]],
		"/(marketing)/faq/[[slug]]": [~26,[3]],
		"/(marketing)/food-recycler": [~27,[3,7]],
		"/(marketing)/(referral)/friends": [~22,[3,5]],
		"/(marketing)/(referral)/friend": [~21,[3,4]],
		"/(marketing)/impact": [~28,[3]],
		"/(marketing)/lab/[...slug]": [~29,[3,8]],
		"/(marketing)/legal/open-source-software-disclosure": [31,[3,9]],
		"/(marketing)/legal/[[slug]]": [30,[3,9,10]],
		"/(marketing)/licenses": [32,[3]],
		"/lp/[slug]": [~41,[15]],
		"/news": [~42,[16]],
		"/news/[slug]": [~43,[16]],
		"/(marketing)/order-now": [~33,[3,11]],
		"/(marketing)/order-now/[orderPage]": [~34,[3,11]],
		"/order/[slug]": [44,[17]],
		"/quiz/[[slug]]": [~45,[18]],
		"/(marketing)/(referral)/refer": [23,[3,6]],
		"/(marketing)/reviews": [~35,[3,12]],
		"/(marketing)/system": [~36,[3]],
		"/(marketing)/team": [~37,[3]],
		"/(landingpage)/[slug]": [19,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';